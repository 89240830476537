import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { WarehouseTabCard } from 'features/warehouses/components/WarehouseTabCard/WarehouseTabCard';
import { Order } from 'trace-backend-sdk';
import { WarehouseService } from 'services/warehouse';
import { Text } from '../../../../../../components/typography';

type WarehouseOrdersCardProps = {
  order: Order;
  role: string;
};

export const WarehouseOrdersCard = ({
  order,
  role,
}: WarehouseOrdersCardProps) => {
  const history = ReactRouter.useHistory();
  const { deleteOrder } = WarehouseService.useDeleteOrder();

  const { id } = order;

  const handleOrderDetailsClick = () => {
    if (id) {
      history.push(`/order/${id}`);
    }
  };

  const handleRemoveOrder = () => {
    const text = 'Are you sure you want to remove this Order?';

    // eslint-disable-next-line no-alert
    if (window.confirm(text)) {
      deleteOrder({ id });
    }
  };

  return (
    <WarehouseTabCard
      role={role}
      name={order.name}
      project={order.projectName}
      client={order.clientName}
      itemsNumber={order.items?.length}
      status={order.status}
      date={order.arrivalDate}
      handleRemoveEvent={handleRemoveOrder}
      handleEventDetailsClick={handleOrderDetailsClick}
      buttonTitle={
        <Text intlId="tab.orders.card.button" variant="button.text" />
      }
    />
  );
};
