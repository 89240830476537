import { Flex } from 'components/layout';
import { Text } from 'components/typography';
import { Button } from 'components/form-elements';
import { ShouldRender } from 'components/ShouldRender';
import { InviteUsersRequestEmailsRoleEnum } from 'trace-backend-sdk';
import { RemoveButton } from '../RemoveButton/RemoveButton';

type WarehouseTabCardProps = {
  name: string;
  client?: string;
  project?: string;
  status?: string;
  date?: string;
  itemsNumber?: number;
  handleRemoveEvent: () => void;
  handleEventDetailsClick: () => void;
  buttonTitle: React.ReactNode;
  role: string;
};

export const WarehouseTabCard = ({
  name,
  client,
  project,
  status,
  date,
  itemsNumber,
  handleRemoveEvent,
  handleEventDetailsClick,
  buttonTitle,
  role,
}: WarehouseTabCardProps) => {
  const newDate = new Date(date || '');
  const formattedDate = date && newDate.toISOString().slice(0, 19).replace('T', ' ').slice(0, -3);
  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: '20px',
        my: '20px',
        borderRadius: 2,
      }}
    >
      <Flex
        sx={{ mr: '20px' }}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Flex flexDirection="column">
          <Text
            sx={{
              fontSize: '4xl',
              fontWeight: 500,
              lineHeight: '24px',
              mb: '5px',
            }}
          >
            {name}
          </Text>

          <Text sx={{ fontStyle: 'italic' }}>
            from{' '}
            <Text variant="body2" sx={{ fontSize: 'xl' }}>
              {client}
            </Text>
          </Text>
        </Flex>

        <Text variant="userCardTitle" sx={{ fontSize: 'lg' }}>
          {project}
        </Text>
      </Flex>

      <Flex
        flexDirection="column"
        justifyContent="space-between"
        gap={1}
        sx={{ width: '200px', flexShrink: 0 }}
      >
        <Flex sx={{ justifyContent: 'space-between' }}>
          <Text
            variant="caption"
            sx={{ fontSize: '16px', textTransform: 'lowercase' }}
          >
            <Text sx={{ fontWeight: 600 }}>{itemsNumber}</Text>
            {itemsNumber && itemsNumber > 1 ? ' items' : ' item'}
          </Text>
          <ShouldRender when={role !== InviteUsersRequestEmailsRoleEnum.User}>
            <RemoveButton onClick={handleRemoveEvent} />
          </ShouldRender>
        </Flex>
        {status && (
          <Text
            sx={{
              fontSize: 'lg',
              fontStyle: 'italic',
            }}
          >
            Status:
            <Text
              sx={{
                color:
                  status === 'REJECTED'
                    ? 'error'
                    : status === 'DELIVERED' || status === 'DISPATCHED'
                      ? 'tertiary1.300'
                      : 'tertiary2.700',
                fontWeight: 600,
              }}
            >
              {' '}
              {status}
            </Text>
          </Text>
        )}
        <Flex alignItems="center" sx={{ gap: '16px' }}>
          <Button
            variant="secondary"
            onClick={handleEventDetailsClick}
            sx={{ height: 'fit-content', py: 3 }}
          >
            {buttonTitle}
          </Button>
        </Flex>

        <Flex justifyContent="flex-end">
          <Text variant="cardLabelSmall" sx={{ fontSize: 'md' }}>
            {formattedDate || 'no date'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
