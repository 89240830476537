import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import { WarehouseTabCard } from 'features/warehouses/components/WarehouseTabCard/WarehouseTabCard';
import { WarehouseService } from 'services/warehouse';
import { Delivery } from 'trace-backend-sdk';
import { Text } from '../../../../../../components/typography';

type WarehouseDeliveriesCardProps = {
  delivery: Delivery;
  role: string;
};

export const WarehouseDeliveriesCard = ({
  delivery,
  role,
}: WarehouseDeliveriesCardProps) => {
  const history = ReactRouter.useHistory();
  const { deleteDelivery } = WarehouseService.useDeleteDelivery();

  const { id } = delivery;

  const handleDeliveryDetailsClick = () => {
    if (id) {
      history.push(`/delivery/${id}`);
    }
  };

  const handleRemoveDelivery = () => {
    const text = 'Are you sure you want to remove this Delivery?';
    // eslint-disable-next-line no-alert
    if (window.confirm(text)) {
      deleteDelivery({ id });
    }
  };

  return (
    <WarehouseTabCard
      role={role}
      name={delivery.name ? delivery.name : ''}
      date={delivery.deliveryDate}
      status={delivery.status}
      itemsNumber={delivery.items.length}
      project={delivery.projectName}
      client={delivery.clientName}
      handleRemoveEvent={handleRemoveDelivery}
      handleEventDetailsClick={handleDeliveryDetailsClick}
      buttonTitle={
        <Text intlId="tab.deliveries.card.button" variant="button.text" />
      }
    />
  );
};
