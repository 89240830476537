import * as React from 'react';
import { UsersService } from 'services/users';
import { Forms } from 'components/form-elements';
import { InviteUsersRequestEmailsRoleEnum } from 'trace-backend-sdk';
import { Flex } from '../../../../components/layout';
import { Image } from '../../../../components/Image';
import { Text } from '../../../../components/typography';
import placeholderImage from '../../../../features/warehouses/assets/placeholderWarehouseImage.jpg';

type OrderWorkerItemCardProps = {
  name?: string;
  project?: string;
  imageUrl?: string;
  category?: string;
  orderedQuantity: number;
  packedQuantity?: number;
  position?: string;
  status?: string;
  packedQuantityName?: string;
  role: string;
};

export const OrderWorkerItemCard = ({
  name,
  project,
  imageUrl,
  orderedQuantity,
  position,
  category,
  status,
  packedQuantityName,
  packedQuantity,
  role,
}: OrderWorkerItemCardProps) => {
  const { image, shouldShowFallback } = UsersService.useFetchAvatarImage(imageUrl);

  const approvedOrderAdminAndClientView = status === 'APPROVED'
    && (role === InviteUsersRequestEmailsRoleEnum.Client
      || role === InviteUsersRequestEmailsRoleEnum.Admin);

  return (
    <Flex
      sx={{
        backgroundColor: 'white.50',
        p: '10px',
        height: 'auto',
        borderRadius: 2,
      }}
    >
      <Flex
        sx={{
          backgroundColor: 'transparent',
          width: '150px',
          height: 'auto',
          mr: '20px',
          flexShrink: 0,
        }}
        alignItems="flex-start"
      >
        <Image
          src={shouldShowFallback ? placeholderImage : image}
          alt={name}
          sx={{
            objectFit: 'cover',
            width: '100%',
            height: '100%',
            borderRadius: 1,
          }}
        />
      </Flex>
      <Flex
        sx={{ mr: '20px' }}
        flexDirection="column"
        justifyContent="space-between"
      >
        <Text
          sx={{
            fontSize: 20,
            fontWeight: 500,
            lineHeight: '20px',
          }}
        >
          {name}
        </Text>
        <Text
          variant="userCardTitle"
          sx={{
            fontWeight: 500,
            fontSize: 'md',
            mt: 'auto',
          }}
        >
          {project}
        </Text>
      </Flex>

      <Flex
        gap={2}
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: 'fit-content', flexShrink: 0, pr: '0px' }}
      >
        <Flex gap={4} alignItems="center">
          <Text
            sx={{
              width: '150px',
              flexShrink: 0,
            }}
            intlId="processOrder.item.card.orderedQuantity"
          />
          <Text sx={{ flexGrow: 1 }}> {orderedQuantity}</Text>
        </Flex>
        <Flex gap={4} alignItems="center">
          <Text
            sx={{
              width: '150px',
              flexShrink: 0,
            }}
            intlId="processDelivery.item.card.position"
          />
          <Text sx={{ flexGrow: 1 }}>{position}</Text>
        </Flex>
        {!approvedOrderAdminAndClientView && (
          <Flex gap={4} alignItems="center">
            <Text
              sx={{
                width: '150px',
                flexShrink: 0,
              }}
              intlId="processOrder.item.card.packedQuantity"
            />

            {packedQuantity || status === 'REJECTED' ? (
              <Text sx={{ flexGrow: 1, maxWidth: '150px' }}>
                {packedQuantity ?? '/'}
              </Text>
            ) : (
              <Forms.FieldEditText
                name={packedQuantityName ?? ''}
                wrapperSx={{ width: '150px', p: 0 }}
                inputSx={{ p: 1 }}
              />
            )}
          </Flex>
        )}

        <Flex sx={{ justifyContent: 'flex-end' }}>
          <Text variant="cardLabelSmall" sx={{ lineHeight: '16px' }}>
            {category || 'No category'}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
