import * as React from 'react';
import * as ReactRouter from 'react-router-dom';
import * as _ from 'lodash-es';
import * as Rhf from 'react-hook-form';
import * as z from 'zod';
import * as Icons from 'components/icons';
import { Button, Forms } from 'components/form-elements';
import { Constants } from 'global/constants';
import { Box, Container, Flex } from 'components/layout';
import { Text } from 'components/typography';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  TemplateIdEnum,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import { useQueryClient } from 'react-query';
import { WorkspaceService } from 'services/workspace';
import { WarehouseService } from 'services/warehouse';
import * as ThemeUI from 'theme-ui';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import { UsersService } from '../../../services/users';
import { USERS_QUERY_KEY } from '../../../services/users/get-users';
import { useNotifications } from '../../../components/notifications/notifications';
import { PaymentsService } from '../../payments';
import { Routing } from '../../../global/routing';

const MAX_INVITES = 10;

function checkEmailRegex(email: unknown) {
  if (typeof email !== 'string') return false;
  if (email.length === 0) return true;

  return Constants.EMAIL_REGEX.test(email);
}

const schema = z.object({
  emails: z.array(
    z.object({
      email: z.custom<string>(checkEmailRegex, {
        message: 'generic.email.invalid',
      }),
      role: z.any(),
      client: z.any(),
    }),
  ),
  addons: z.array(z.string()).optional(),
});

export function InviteNewUsersPage() {
  const history = ReactRouter.useHistory();
  const notifications = useNotifications();
  const queryClient = useQueryClient();
  const { workspaceData } = WorkspaceService.useGetWorkspace();

  const templates = workspaceData?.availableTemplates ?? [];

  const { workspaceClients, isLoading } = WarehouseService.useGetWorkspaceClients({
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const defaultClientId = data.clients[0].id || '';
      const currentEmails = formMethods.getValues('emails');

      const updatedEmails = currentEmails.map((emailData) => ({
        ...emailData,
        client: defaultClientId,
      }));

      formMethods.setValue('emails', updatedEmails);
    },
  });

  PaymentsService.useGetTemplatePrices({
    refetchOnWindowFocus: false,
    onSuccess: () => {
      formMethods.reset({
        ...formMethods.getValues(),
        addons: [...templates],
      });
    },
  });

  const { inviteUsers, isLoading: isInvitingUsers } = UsersService.useInviteUsers({
    async onSuccess(data) {
      const failedInvites = data.users.filter(
        (user) => user.succeeded === false,
      );
      failedInvites.forEach((failedInvite) => {
        notifications.info({
          description: 'inviteUsers.alreadyExists',
          durationMs: 'Infinity',
          intlValues: {
            user: failedInvite.email,
          },
        });
      });

      formMethods.reset({
        emails: [
          {
            email: '',
            role: InviteUsersRequestEmailsRoleEnum.Admin,
            client: workspaceClients ? workspaceClients[0].id : 'a',
          },
        ],
        addons: [...templates],
      });

      if (failedInvites.length === 0) {
        history.push(
          `${Routing.TRANSITIONS.SuccessConfirmation}?key=inviteUsers.success`,
        );
      }

      await queryClient.invalidateQueries(USERS_QUERY_KEY);
    },
  });

  const formMethods = Rhf.useForm<z.infer<typeof schema>>({
    defaultValues: {
      emails: [
        {
          email: '',
          role: InviteUsersRequestEmailsRoleEnum.Admin,
          client: workspaceClients ? workspaceClients[0].id : 'a',
        },
      ],
      addons: templates,
    },
    resolver: zodResolver(schema),
  });
  console.log(formMethods.getValues());

  const fieldArray = Rhf.useFieldArray({
    name: 'emails',
    control: formMethods.control,
  });

  const hasAtLeastOneValue = formMethods
    .watch('emails')
    .some(({ email }) => (email ? email.length > 0 : false));

  const emailsData = formMethods.watch('emails');

  if (isLoading) {
    return <InlineLoadingIndicator />;
  }

  return (
    <Forms.Provider
      {...formMethods}
      name="emails"
      onValid={(data) => {
        // const emailsTest = data.emails
        //   .filter(({ email }) => Boolean(email))
        //   .map(({ email, role, client }) => ({
        //     email,
        //     role,
        //     client,
        //   }));
        inviteUsers({
          emails: data.emails
            .filter(({ email }) => Boolean(email))
            .map(({ email, role, client }) => ({
              email,
              role,
              ...(role === InviteUsersRequestEmailsRoleEnum.Client && {
                clientId: client,
              }),
            })),
          addons:
            typeof data.addons === 'string'
              ? [data.addons as TemplateIdEnum]
              : (data.addons as TemplateIdEnum[]).filter(_.identity),
        });
      }}
      sx={{ p: 0, gap: 0, flexGrow: 1 }}
    >
      <Container variant="spacer" sx={{ mt: '10px' }}>
        <Flex flexDirection="row" justifyContent="flex-start">
          <IconWrapper>
            <Icons.UserWithBackground fill="#012169" />
          </IconWrapper>
          <Text
            as="h2"
            intlId="payments.inviteNewUsers.addNewUsers.title"
            variant="body1"
            sx={{
              color: 'primary.900',
              textTransform: 'uppercase',
              fontWeight: '500',
              mb: 2,
            }}
          />
        </Flex>
        <Text
          as="p"
          variant="body2"
          intlId="payments.inviteNewUsers.addNewUsers.description"
          sx={{ mb: 7 }}
        />
        <Text
          as="h2"
          intlId="payments.inviteNewUsers.addNewUsers.email"
          variant="body1"
          sx={{
            color: 'primary.900',
            textTransform: 'capitalize',
            fontWeight: '500',
            mb: 5,
          }}
        />
        {fieldArray.fields.map((value, index) => {
          const roleValue = emailsData?.[index]?.role;
          return (
            <>
              <Box sx={{ pb: 5 }}>
                <Flex gap={4}>
                  <Forms.FieldEditText
                    placeholderIntlId="payments.inviteNewUsers.email.placeholder"
                    key={value.id}
                    name={getFieldArrayName('email', index)}
                    labelIntlId="login.form.input.email.label"
                    isPlaceholderAlwaysVisible
                    isHoverEffectVisible
                  />
                  <Forms.SelectField
                    name={getFieldArrayName('role', index)}
                    labelIntlId="login.form.input.email.label"
                    isLabelAlwaysLifted
                  >
                    {Object.values(InviteUsersRequestEmailsRoleEnum).map(
                      (role) => (
                        <option value={role} key={role}>
                          {role.charAt(0).toUpperCase() + role.slice(1)}
                        </option>
                      ),
                    )}
                  </Forms.SelectField>
                </Flex>
                {roleValue === InviteUsersRequestEmailsRoleEnum.Client && (
                  <Box sx={{ width: '360px', mt: 3 }}>
                    <Forms.SelectField
                      name={getFieldArrayName('client', index)}
                    >
                      {workspaceClients?.map((client) => (
                        <option value={client.id} key={client.id}>
                          {client.name}
                        </option>
                      ))}
                    </Forms.SelectField>
                  </Box>
                )}
                <ThemeUI.Divider
                  sx={{ mt: 5, mb: 0, borderColor: 'grey.200' }}
                />
              </Box>
            </>
          );
        })}

        <React.Fragment>
          <Flex flexDirection="row" justifyContent="flex-end">
            <Button
              disabled={
                fieldArray.fields
                  ? fieldArray.fields.length >= MAX_INVITES
                  : false
              }
              onClick={() => fieldArray.append({ email: '' })}
              sx={{
                width: 'fit-content',
                fontSize: '16px',
                fontWeight: '500',
                color: '#3192F1',
                pt: '0',
                textTransform: 'capitalize',
                '& > span': { mr: '0px' },
              }}
              variant="text"
              prependIcon={<Icons.Add width={17} />}
              intlId="payments.inviteNewUsers.addMore"
            />
          </Flex>
        </React.Fragment>
      </Container>

      <Flex
        sx={{
          cursor: !hasAtLeastOneValue && 'not-allowed',
          mt: 'auto',
          justifyContent: 'center',
        }}
      >
        <Forms.SubmitButton
          isLoading={isInvitingUsers}
          intlId="payments.inviteNewUsers.buttonLabel"
          sx={{
            maxWidth: '300px',
            pointerEvents: !hasAtLeastOneValue && 'none',
            mb: '30px',
          }}
        />
      </Flex>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',

          position: 'sticky',
          bottom: 0,
          backgroundColor: 'white.50',
          py: 3,
          boxShadow: '16dp',
        }}
      />
    </Forms.Provider>
  );
}

function getFieldArrayName(name: string, index: number) {
  return ['emails', index, name].join('.');
}

function IconWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      justifyContent="flex-start"
      alignItems="flex-start"
      sx={{ width: 'fit-content', mr: 2 }}
    >
      {children}
    </Flex>
  );
}
