import { DocumentDetail } from 'pages/Document/components/DocumentEdit/components/DocumentDetails';
import * as Icons from 'components/icons';
import * as Rhf from 'react-hook-form';
import { Button, Forms } from 'components/form-elements';
import { Grid } from 'components/layout';
import * as ReactRouter from 'react-router-dom';
import Joi from 'joi';
import { ValidationSchemas } from 'utils/validation';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  FinalizeOrderRequestItems,
  GetOrderResponse,
  InviteUsersRequestEmailsRoleEnum,
} from 'trace-backend-sdk';
import { WarehouseService } from 'services/warehouse';
import * as ReactQuery from 'react-query';
import { InlineLoadingIndicator } from 'components/LoadingIndicator';
import SuccessMessage from 'features/warehouses/components/SuccessMessage/SuccessMessage';
import { QUERY_KEY_ORDER } from 'services/warehouse/get-order';
import { OrderWorkerItemCard } from '../OrderWorkerItemCard/OrderWorkerItemCard';
import { Text } from '../../../../components/typography';

type OrderWorkerViewProps = {
  order?: GetOrderResponse;
  role: string;
};

const buildFinalizeOrderRequestItems = (
  order: GetOrderResponse,
  orderItems: { packedQuantity: number }[],
): FinalizeOrderRequestItems[] | undefined => {
  const finalizedItems = order?.items?.map((item, index) => {
    const formItem = orderItems[index];

    return {
      orderItemId: item.id,
      orderedQuantity: item.orderedQuantity,
      packedQuantity: formItem.packedQuantity,
    };
  });
  return finalizedItems;
};

export const OrderWorkerView = ({ order, role }: OrderWorkerViewProps) => {
  const history = ReactRouter.useHistory();
  const queryClient = ReactQuery.useQueryClient();
  const params = ReactRouter.useParams<{ id: string }>();

  const newDate = new Date(order?.arrivalDate || '');
  const formattedDate = order?.arrivalDate
    && newDate.toISOString().slice(0, 19).replace('T', ' ').slice(0, -3);
  const orderId = params.id;

  const splittedNotes = order?.notes && order.notes.split(',');

  const adminNotes = splittedNotes && splittedNotes[0];
  const workerNotes = splittedNotes && splittedNotes[1];

  const {
    finalizeOrder,
    isSuccess: isFinalizeOrderSuccess,
    reset: resetFinalizeOrder,
  } = WarehouseService.useFinalizeOrder({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ORDER(orderId)),
      ]);
      setTimeout(() => {
        resetFinalizeOrder();
      }, 1500);
    },
  });

  const {
    rejectOrder,
    isLoading: isRejectOrderLoading,
    isSuccess: isRejectOrderSuccess,
    reset,
  } = WarehouseService.useRejectOrder({
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries(QUERY_KEY_ORDER(orderId)),
      ]);
      setTimeout(() => {
        reset();
      }, 1500);
    },
  });

  const formSchema = Joi.object({
    orderItems: Joi.array().items(
      Joi.object({
        packedQuantity: ValidationSchemas.ProcessOrder.PACKED_QUANTITY,
      }),
    ),
    notes: ValidationSchemas.ProcessOrder.NOTES,
  });

  const formMethods = Rhf.useForm({
    resolver: joiResolver(formSchema),
  });

  if (isRejectOrderLoading) {
    return <InlineLoadingIndicator />;
  }

  const isOrderApproved = order?.status === 'APPROVED';
  const isOrderRejected = order?.status === 'REJECTED';
  const isOrderFinalized = order?.status === 'DISPATCHED';

  const approvedOrderAdminView = isOrderApproved && role === InviteUsersRequestEmailsRoleEnum.Admin;
  const approvedOrderClientView = isOrderApproved && role === InviteUsersRequestEmailsRoleEnum.Client;
  const approvedOrderWorkerView = isOrderApproved && role === InviteUsersRequestEmailsRoleEnum.User;

  if (isRejectOrderSuccess) {
    return (
      <SuccessMessage
        successMessage="rejectOrder.success"
        animationAndTextColor="red"
      />
    );
  }

  if (isFinalizeOrderSuccess) {
    return <SuccessMessage successMessage="finalizeOrder.success" />;
  }

  return (
    <Forms.Provider
      onValid={(data) => {
        if (order) {
          const finalizedItems = buildFinalizeOrderRequestItems(
            order,
            data.orderItems,
          );
          finalizeOrder({
            orderId,
            finalizedData: {
              notes: order.notes
                ? `${order.notes}, Worker notes: ${data.notes}`
                : data.notes,
              items: finalizedItems || [],
            },
          });
        }
      }}
      name="finishOrder"
      {...formMethods}
    >
      {approvedOrderAdminView
      || approvedOrderClientView
      || isOrderRejected
      || isOrderFinalized ? (
        <Text
          sx={{
            width: 'fit-content',
            alignSelf: 'flex-end',
            color: isOrderRejected ? 'error' : 'tertiary1.300',
            fontWeight: 500,
          }}
        >
          {order.status}
        </Text>
        ) : (
          <Button
            prependIcon={<Icons.Close sx={{ stroke: 'error' }} width={20} />}
            variant="text"
            sx={{
              width: 'fit-content',
              alignSelf: 'flex-end',
              color: 'error',
              gap: 0,
            }}
            intlId="processOrder.rejectOrder.button"
            onClick={() => rejectOrder({ orderId })}
          />
        )}
      <DocumentDetail
        sx={{ p: 2 }}
        label="createOrder.form.name"
        icon={<Icons.FileMinus width="35px" />}
      >
        <Text>{order?.name}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="createWarehouse.form.input.address.label"
        icon={<Icons.AddressCircle width="35px" />}
      >
        <Text>{order?.address}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="login.form.input.email.label"
        icon={<Icons.EmailCircle width="35px" />}
      >
        <Text>{order?.email}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="createAccount.form.input.phoneNumber.label"
        icon={<Icons.PhoneCircle width="35px" />}
      >
        <Text>{order?.phoneNumber}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.project"
        icon={<Icons.Building width="35px" />}
      >
        <Text>{order?.projectName}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.worker"
        icon={<Icons.Assignee width="35px" />}
      >
        <Text>{order?.workerName}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="createDelivery.form.deliveryDate.label"
        icon={<Icons.Created width="35px" />}
      >
        <Text>{formattedDate}</Text>
      </DocumentDetail>
      <DocumentDetail
        sx={{ p: 2 }}
        label="delivery.addDelivery.deliveryNotes"
        icon={<Icons.CreatedBy width="35px" />}
      >
        <Text>
          {adminNotes}
          <br />
          {workerNotes}
        </Text>
      </DocumentDetail>
      {order?.items?.map((item, index) => (
        <OrderWorkerItemCard
          status={order.status}
          project={order.projectName}
          category={item.category}
          orderedQuantity={item.orderedQuantity}
          position={item.position}
          name={item.name}
          packedQuantityName={`orderItems[${index}].packedQuantity`}
          packedQuantity={item.packedQuantity}
          role={role}
        />
      ))}
      {order?.status === 'APPROVED'
        && role === InviteUsersRequestEmailsRoleEnum.User && (
          <Forms.FieldEditText
            labelIntlId="processDelivery.form.input.workerNotes.label"
            name="notes"
          />
      )}
      <Grid
        columns={
          isOrderRejected
          || approvedOrderAdminView
          || approvedOrderClientView
          || isOrderFinalized
            ? 1
            : 2
        }
        sx={{ justifyItems: 'center' }}
      >
        <Button
          sx={{ maxWidth: '490px' }}
          variant="preview"
          onClick={() => history.goBack()}
          intlId="generic.button.goBack"
        />
        {approvedOrderWorkerView
          && !approvedOrderAdminView
          && !approvedOrderClientView && (
            <Forms.SubmitButton sx={{ height: 'auto' }}>
              <Text intlId="processOrder.finishOrder.button" />
            </Forms.SubmitButton>
        )}
      </Grid>
    </Forms.Provider>
  );
};
